.rm__project-header {
    color: #9CF299;
    text-align: center;
    font-family: var(--font-normal);
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: 20%;
    margin-left: 30%;
    margin-right: 30%;
}

.rm__project-body {
    display: grid;
    text-align: left;
    margin-top: 5%;
    margin-left: 20%;
    margin-right: 20%;
    align-items: center;
}

.rm__project-body h1 {
    color: #FFF;
    font-family: var(--font-normal);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 186.4%;
    /* 67.104px */
    letter-spacing: 1.8px;
    text-transform: uppercase;
}

.rm__project-body h2 {
    color: #BBF2D8;
    font-family: var(--font-normal);
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    line-height: 186.4%;
    margin-top: -2%;
}

.rm__project-body-image {
    display: flex;
    flex-direction: row;
    width: auto;
    max-height: 430px;
    align-items: center;
}

.rm__project-body-text {
    color: #FFF;
    text-align: center;
    font-family: var(--font-normal);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 186.4%;
    letter-spacing: 0.8px;
    margin-top: 5%;
}

.rm__project-body-skills {
    color: #FFF;
    text-align: center;
    font-family: var(--font-normal);
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.65px;
    margin-top: 5%;
}

.rm__project-body-links {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    margin-bottom: 10%;
}

.rm__project-body-links a:link {
    text-decoration: none;
}

.rm__project-body-links button {
    width: 128px;
    height: 38px;
    flex-shrink: 0;
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.30);
    background: #F28729;
    box-shadow: 0px 7px 14px 2px rgba(0, 0, 0, 0.16);
    color: #FFF;
    text-align: center;
    font-family: var(--font-normal);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.12px;
    margin-top: 50%;
}
